



















































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import TimePicker from "@/components/TimePicker.vue";
import DatePicker from "@/components/DatePicker.vue";
import { required } from "@/shared/validation";
import {
  get_setting_department,
  patch_setting_shift,
  post_setting_shift,
} from "@/api";
import { settingDepartmentType, settingShiftDetailType } from "@/api/api.types";

@Component({ name: "shift-add", components: { TimePicker, DatePicker } })
export default class ShiftAdd extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!:
    | boolean
    | settingShiftDetailType["data"];

  required = required;

  /* Variables */

  formData = {
    name: "",
    department: "",
    timeIn: "",
    timeOut: "",
    startDate: "",
    endDate: "",
    isAvailable: 0,
    note: "",
  };

  date = [] as string[];

  departmentList =
    [] as settingDepartmentType["data"]["_embedded"]["department"];

  uuid = null as null | string;

  loading = false;

  /* Computed */

  public get workingHour(): number {
    let time = 0;
    if (this.formData.timeIn && this.formData.timeOut) {
      const temp_timeIn = this.formData.timeIn
        ?.split(":")
        .map((t) => parseInt(t));
      const temp_timeOut = this.formData.timeOut
        ?.split(":")
        .map((t) => parseInt(t));
      const temp_time = [
        temp_timeIn[0] + temp_timeIn[1] / 60,
        temp_timeOut[0] + temp_timeOut[1] / 60,
      ];
      if (temp_time[0] > temp_time[1]) temp_time[1] += 24;
      time = temp_time[1] - temp_time[0];
    }
    return time;
  }

  /* Methods */

  async fetchDepartment(): Promise<void> {
    try {
      const resp = await get_setting_department({ limit: -1 });
      this.departmentList = resp.data._embedded.department;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
        workingHour: this.workingHour,
      };
      if (!this.uuid) {
        await post_setting_shift(form);
        Notif.notif_success("Shift Successfully Added!!!");
      } else {
        await patch_setting_shift(this.uuid, form);
        Notif.notif_success("Shift Successfully Editted!!!");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      department: "",
      timeIn: "",
      timeOut: "",
      startDate: "",
      endDate: "",
      isAvailable: 0,
      note: "",
    };
    this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: boolean | settingShiftDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        department: now.department ? now.department.uuid : "",
        timeIn: now.timeIn,
        timeOut: now.timeOut,
        startDate: now.startDate,
        endDate: now.endDate,
        isAvailable: now.isAvailable,
        note: now.note,
      };
      this.date = [this.formData.startDate, this.formData.endDate];
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchDepartment();
  }
}
